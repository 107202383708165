import { dynamicAttributeTypeOptions } from '@/libs/domains/settings/dynamic/attribute/filters'
import type { DynamicGroupFilter } from '@futureecom/futureecom-js/dist/services/setting-service'
import type { FilterDefinition } from '@/types/FilterDefinitions'

export const dynamicGroupFilters = (resourceTypes: string[]): FilterDefinition<DynamicGroupFilter>[] => [
  { type: 'text', code: 'order', label: 'Order' },
  { type: 'text', code: 'code', label: 'Code' },
  { type: 'text', code: 'label', label: 'Label' },
  { type: 'text', code: 'dynamic_attributes.code', label: 'Dynamic Attribute Code' },
  { type: 'text', code: 'dynamic_attributes.label', label: 'Dynamic Attribute Label' },
  {
    type: 'array',
    code: 'resource_types',
    label: 'Resource Types',
    options: resourceTypes
  },
  {
    type: 'select-multiple',
    code: 'dynamic_attributes.type',
    label: 'Dynamic Attribute type',
    options: dynamicAttributeTypeOptions
  },
  { type: 'date', code: 'created_at', label: 'Created at' },
  { type: 'date', code: 'updated_at', label: 'Updated at' }
]
